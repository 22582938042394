import React from "react";
import { useSetFamilyGoalPreferenceMutation } from "services/graphql";
import { incrementStep } from "app/routes/Register/helpers";
import { useHistory, useLocation } from "react-router";
import { onboardingSelectFamilyGoal } from "services/typewriter/segment";
import { ANALYTICS_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { Option } from "../../Option";
import { FamilyGoalsWrapper } from "./FamilyGoalsWrapper";
import { useAnimate } from "../useAnimate";

interface Props {
  familyGoals?: FamilyGoal[];
  isClickDisabled: boolean;
  toggleIsClickDisabled(isClickDisabled: boolean): void;
}

interface FamilyGoal {
  description: string;
  label: string;
  previewUrl: string;
  slug: string;
}

interface SetFamilyGoalResult {
  label: string;
}

export function FamilyGoals({
  familyGoals = [],
  isClickDisabled,
  toggleIsClickDisabled,
}: Props) {
  const location = useLocation();
  const history = useHistory();
  const { animationControls, animate } = useAnimate();

  const [setFamilyGoal] = useSetFamilyGoalPreferenceMutation({
    onCompleted: ({ setFamilyGoalPreference: setFamilyGoalResult }) => {
      const { label }: SetFamilyGoalResult = setFamilyGoalResult;

      onboardingSelectFamilyGoal({
        module: ANALYTICS_MODULE.onboarding,
        location: ANALYTICS_LOCATION.onboarding,
        family_goal: label,
      });

      incrementStep({ stepsToIncrement: 1, location, history });

      toggleIsClickDisabled(false);
    },
  });

  return (
    <FamilyGoalsWrapper animationControls={animationControls}>
      {familyGoals &&
        familyGoals.map((familyGoal: FamilyGoal) => (
          <Option
            key={familyGoal.slug}
            onSelect={() => {
              const { slug } = familyGoal;

              if (isClickDisabled) {
                return;
              }

              toggleIsClickDisabled(true);

              setTimeout(animate, 200);

              setFamilyGoal({ variables: { slug } });
            }}
            label={familyGoal.label}
            slug={familyGoal.slug}
            description={familyGoal.description}
          />
        ))}
    </FamilyGoalsWrapper>
  );
}
